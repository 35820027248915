div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Lalezar";
        color: black;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        white-space: pre-wrap;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 20vh;
        right: 5vh;
        text-align: center;
        font-size: 5vh;
        font-family: "Lalezar";
        color: white;
        text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {
        .playerRow {
            display: flex;
            width: auto;
            height: auto;
            justify-content: center;
            align-items: center;

            .player {
                width: 12.5vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;

                &.show {
                    transform: scale(1);
                }

                .chameleon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(1.15);
                }
            }
        }
    }

    .stepTwo {
        .numbersSection {
            margin: auto;
            display: flex;
            width: 100%;
            height: 100%;
            user-select: none;
            touch-action: none;
            justify-content: start;
            align-items: center;
            flex-direction: column;
            gap: 5vh;


            .goalSection {
                position: fixed;
                width: 50vh;
                top: 0;
                height: fit-content;
                justify-content: center;
                align-content: center;
                transform: translateY(-50vh);
                transition: 1s transform ease-out;
                opacity: 0;

                &.show {
                    transform: translateY(0vh);
                    opacity: 1;
                }

                .goalBg {
                    position: absolute;
                    width: 100%;
                    height: auto;
                }

                .goalText {
                    position: relative;
                    font-family: "Lalezar";
                    font-size: 12vh;
                    color: #002026;
                    z-index: 1;
                    text-align: center;
                    text-shadow: 0.2vh 0.2vh 0px white;
                    transform: translateY(-2vh);
                }
            }

            .numbersGroup {
                position: relative;
                width: 95vh;
                height: fit-content;
                /*transform: scale(0);
            transition: 1s linear ease-in-out;

            &.show {*/
                /*opacity: 1;*/
                /*transform: scale(1);
            }*/

                .numbersBg {
                    position: absolute;
                    width: 100%;
                    height: auto;
                }

                .numbersRow {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 97%;
                    flex-wrap: wrap;
                    margin: auto;
                    gap: min(2vw, 2vh);

                    .number {
                        min-width: min(12vh, 12vw);
                        width: fit-content;
                        height: min(12vh, 12vw);
                        //padding: min(1vw, 1vh);
                        background-color: #fffdf5;
                        border-radius: 1.5vh;
                        box-shadow: 0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.1vw, 0.1vh) #207ba3, //inner medium blue
                        0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.6vw, 0.6vh) #0e5e87, //outer dark blue
                        0px max(-0.5vw, -0.5vh) min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) #abdbe8 inset; //inset blue glow
                        transform: scale(0);
                        transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                        &.show {
                            transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                            transform: scale(1);
                        }

                        .numberText {
                            text-align: center;
                            font-size: min(8.5vw, 8.5vh);
                            line-height: 0;
                            padding: min(6.5vw, 6.5vh) min(1vw, 1vh);
                            margin: auto;
                            font-family: "Lalezar";
                            color: #002026;
                            z-index: 1;
                            font-family: "Lalezar";
                        }
                    }
                    /*.number {
                    position: relative;
                    font-family: "Lalezar";
                    font-size: 8.5vh;
                    color: #002026;
                    z-index: 1;
                    text-align: center;
                    flex: 1 0 16%;*/ /* explanation below */
                    /*}*/
                }
            }
        }
    }

    .stepThree {

        .closestAnswerSection {
            display: inline-flex;
            bottom: 5vh;
            margin-left: auto;
            margin-right: auto;
            width: 45vh;
            height: auto;
            justify-content: center;
            grid-gap: 1.5vh;
            gap: 1.5vh;

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 25vh;
                width: 25vh;
                animation: raise 1s forwards;

                &.first {
                    width: 35vh;
                    top: -5vh;
                }

                &.hide {
                    animation: hide 1s forwards;
                }

                .nameBox {
                    width: fit-content;
                    line-height: 1;
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }

                .name {
                    font-family: "Lalezar";
                    z-index: 1;
                    font-size: 4vh;
                    color: black;
                    background-color: #FDC31F;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.5vh 0 0 #D87715;
                    padding-left: 1vh;
                    padding-right: 1vh;
                    padding-top: 0.65vh;
                    padding-bottom: 0.4vh;
                    text-transform: uppercase;
                    text-align: center;
                    overflow: hidden;
                }
            }

            .answer {
                position: relative;
                height: fit-content;
                width: fit-content;
                float: right;
                top: 0;
                background-color: rgba(255, 254, 250, 0.15);
                border-radius: 1vh;
                padding: 2vh;
                opacity: 0;
                transition: opacity ease-in-out 0.5s;

                &.show {
                    opacity: 1;
                }

                .equation {
                    font-size: 3vh;
                    font-weight: 700;
                    animation: fade 0.5s ease-out var(--delay) forwards;
                    margin: 0;
                    opacity: 0;
                    line-height: 5vh;
                    text-wrap: nowrap;

                    &.playerAnswer {
                        border-top: 0.5vh solid black;
                        border-bottom: 0.5vh solid black;
                        line-height: 4vh;
                        margin-top: 0.5vh;
                    }
                }

                @keyframes fade {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }

            @keyframes raise {
                0% {
                    transform: translateY(100vh);
                }

                40% {
                    transform: translateY(0) scale(1.1);
                }

                60% {
                    transform: scale(1) rotate(5deg);
                }

                80% {
                    transform: scale(1.05) rotate(-5deg);
                }

                100% {
                    transform: scale(1) rotate(0);
                }
            }

            @keyframes hide {
                0% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(100vh);
                }
            }
        }
    }

    .stepFour {
        .stepFourBubble {
            width: 50vh;
            margin-left: auto;
            margin-right: auto;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }
    }

    .stepFive {
    }
}
