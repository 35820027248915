[contenteditable] {
    outline: 0px solid transparent;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: linear-gradient(30deg, rgba(119,113,248,1) 0%, rgba(83,255,186,1) 100%);
    /*    background-color: #5DD9CB;
    background-image: url('../../images/Host/maths-master-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    overflow: hidden;

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 5vh;
            text-align: center;
            line-height: 5vh;
            height: 7vh;
            color: black;
            //height: fit-content;
            width: fit-content;
            padding: 1.5vh;
            margin-left: auto;
            margin-right: auto;
            font-family: 'Lalezar';
            user-select: inherit !important;
            cursor: pointer;
            transform: scale(1);
            background: linear-gradient(0deg, var(--button-bot) 0%, var(--button-top) 100%);
            box-shadow: 0 0 0 0.4vh #ffffff, 0 0 0 0.8vh var(--button-boarder);
            border-radius: 2vh;
            min-width: 29vh;

            &.alt {
                background: linear-gradient(0deg, var(--alt-button-bot) 0%, var(--alt-button-top) 100%);
            }
        }
    }


    .answerSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: min(1vh, 15px);
        width: 100%;
        height: 100%;

        .goalSection {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 300px;
            height: fit-content;
            justify-content: center;
            align-content: center;

            .goalBg {
                position: absolute;
                width: 100%;
                height: auto;
            }

            .goalText {
                position: relative;
                font-family: "Lalezar";
                font-size: 75px;
                color: #002026;
                z-index: 1;
                text-align: center;
                text-shadow: 5px 5px 0px white;
                transform: translateY(-2vh);
            }
        }

        .infoBox {
            width: fit-content;
            height: fit-content;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fffefa;
                opacity: 0.15;
                border-radius: 10px;
            }

            .innerText {
                font-size: 25px;
                font-family: 'Lalezar';
                color: #002026;
                text-align: center;
                line-height: 1;
            }
        }

        .numbersGroup {
            position: relative;
            width: 320px;
            height: fit-content;
            min-height: 45px;
            display: flex;
            align-items: center;

            .numbersBg {
                position: absolute;
                width: 100%;
                height: auto;
            }

            .numbersRow {
                position: relative;
                display: flex;
                justify-content: start;
                align-items: center;
                width: 97%;
                flex-wrap: nowrap;
                margin: auto;
                padding-bottom: 5px;

                .number {
                    position: relative;
                    font-family: "Lalezar";
                    font-size: 30px;
                    color: #002026;
                    z-index: 1;
                    text-align: center;
                    width: 52px;

                    &.fade {
                        opacity: 0.25;
                    }

                    &.small {
                        font-size: 22px;
                    }

                    &.xSmall {
                        font-size: 16px;
                    }

                    &.tiny {
                        font-size: 12px;
                    }
                }
            }
        }

        .operatorsGroup {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .operator {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: fit-content;
                width: fit-content;

                &.fade {
                    opacity: 0.25;
                }

                .operatorBg {
                    height: auto;
                    width: 50px;
                }

                .operatorText {
                    font-size: 30px;
                    font-family: "Lalezar";
                    color: #002026;
                    /*line-height: 1;*/
                    text-align: center;
                    position: absolute;
                    width: fit-content;
                    margin: auto;
                    top: 0;
                    bottom: 7.5px;
                    right: 0;
                    left: 0;
                    height: fit-content;
                    text-transform: capitalize;
                }
            }
        }

        .answerBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            min-width: 280px;
            min-height: 70px;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 10px;
            padding-left: 10px;
            padding-right: 10px;


            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: 10px;
            }

            .answerSegment {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: 50px;
                width: 25%;
                font-size: 50px;
                font-family: "Lalezar";
                color: #002026;
                line-height: 1;
                text-align: center;
                text-transform: capitalize;
                transform: translateY(0.5vh);



                &.fade {
                    opacity: 0.25;
                }

                &.small {
                    font-size: 40px;
                }

                &.xSmall {
                    font-size: 30px;
                }

                &.tiny {
                    font-size: 20px;
                }
            }
        }
    }

    .lastNumberSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .opacityBackground {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #002026;
            opacity: 0.75;
        }

        .innerBox {
            position: relative;
            width: 75%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: min(5vw, 5vh);
            padding-top: 0;
            background-image: url('../../images/Host/maths-master-background.png');
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-radius: 10px;

            .bestNumber {
                position: relative;
                margin: auto;
                width: 200px;
                height: 70px;
                justify-content: center;
                align-content: center;

                .bestNumberBg {
                    position: absolute;
                    width: 100%;
                    height: auto;
                }

                .bestNumberText {
                    position: relative;
                    font-family: "Lalezar";
                    font-size: 60px;
                    color: #002026;
                    z-index: 1;
                    text-align: center;
                    text-shadow: 3px 3px 0px white;
                    transform: translateY(-2vh);
                }
            }

            .innerText {
                font-size: 25px;
                font-family: 'Lalezar';
                color: #002026;
                text-align: center;
                line-height: 1;
            }

            .buttonGroup {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                position: absolute !important;
                margin: auto;
                left: 0;
                right: 0;
                bottom: -20px;
                justify-content: center;
                gap: 10px;
            }

            .button {
                width: 120px;
            }
        }
    }

    .button {
        position: relative;
        font-size: 35px;
        font-family: "Lalezar";
        padding-bottom: 10px;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 1;
        background-color: transparent;
        color: black;
        border: none;
        text-transform: uppercase;
        z-index: 1;

        &.smaller {
            font-size: 20px;
        }

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    .button:disabled {
        .buttonImg {
            opacity: 0.50;
        }
    }

    .timerSection {
        position: absolute;
        width: min(40vh, 80vw);
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 0;
        gap: 1vh;
        touch-action: none;
        user-select: none;

        &.show {
            transform: translateY(0%);
        }

        .clockBg {
            position: absolute;
            width: 100%;
            height: auto;
            margin: auto;
            left: 50%;
            bottom: 0;
            transform: translatex(-50%);
        }

        .timerText {
            text-align: center;
            font-size: 7vh;
            font-family: "Lalezar";
            color: white;
            width: 25%;
            height: fit-content;
            line-height: 1;
            margin-top: 20%;
            text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
        }

        .clock {
            position: relative;
            width: 20%;
            height: auto;
            margin-top: 17%;

            .clockImg {
                width: 100%;
                height: auto;
            }

            .clockHand {
                position: absolute;
                width: 14%;
                height: auto;
                margin: auto;
                left: 0;
                right: 0;
                top: 25.5%;
                animation: spin 4s linear;
                animation-iteration-count: infinite;
                transform-origin: 47% 87%;
                animation: spin 5s linear infinite;

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}
