[contenteditable] {
    outline: 0px solid transparent;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: linear-gradient(30deg, rgba(24,221,254,1) 0%, rgba(65,184,254,1) 100%);
    /* background-image: url('../../images/Host/anagram-magic-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    overflow: hidden;

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 5vh;
            text-align: center;
            line-height: 5vh;
            height: 7vh;
            color: black;
            //height: fit-content;
            width: fit-content;
            padding: 1.5vh;
            margin-left: auto;
            margin-right: auto;
            font-family: 'Lalezar';
            user-select: inherit !important;
            cursor: pointer;
            transform: scale(1);
            background: linear-gradient(0deg, var(--button-bot) 0%, var(--button-top) 100%);
            box-shadow: 0 0 0 0.4vh #ffffff, 0 0 0 0.8vh var(--button-boarder);
            border-radius: 2vh;
            min-width: 29vh;

            &.alt {
                background: linear-gradient(0deg, var(--alt-button-bot) 0%, var(--alt-button-top) 100%);
            }
        }
    }

    .letterSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: min(4vw, 4vh);

        .infoBox {
            width: fit-content;
            height: fit-content;
            padding: min(2vw, 2vh);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: min(2vw, 2vh);
            }

            .innerText {
                font-size: min(4.5vw, 4vh);
                font-family: 'Lalezar';
                color: black !important;
                text-align: center;
                line-height: 1;
                padding-top: min(0.4vw, 0.4vh);
            }
        }

        .bigButton {
            color: rgb(33, 37, 41);
            font-size: min(10vw, 10vh);
            height: min(12vw, 12vh);
            line-height: min(8vw, 8vh);
            padding: min(3vw, 3vh);
            text-transform: uppercase;
            background-color: #fffdf5;
            border-radius: min(2vw, 2vh);
            border: none;
            box-shadow: 0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.1vw, 0.1vh) #207ba3, //inner medium blue
            0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.6vw, 0.6vh) #0e5e87, //outer dark blue
            0px min(-0.5vw, -0.5vh) min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) #abdbe8 inset; //inset blue glow
            font-family: "Lalezar";
            transition: opacity 0.5s ease-in-out, box-shadow 0.1s linear, transform 0.1s linear;

            &:disabled {
                opacity: 0.5;
            }

            &:active {
                background-color: #f3f1e8;
                box-shadow: 0px 0 0 0 #207ba3, 0px min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) min(0.3vw, 0.3vh) #0e5e87, //inner medium blue
                0px min(-0.5vw, -0.5vh) min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) #abdbe8 inset; //inset blue glow
                transform: translateY(4px);
            }
        }
    }

    .answerSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .infoBox {
            width: fit-content;
            height: fit-content;
            padding: min(2vw, 2vh);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: min(2vw, 2vh);
            }

            .innerText {
                font-size: min(5.5vw, 4.5vh);
                font-family: 'Lalezar';
                color: black !important;
                text-align: center;
                line-height: 1;
                padding-top: min(0.4vw, 0.4vh);
            }
        }

        .anagram {
            display: flex;
            margin-top: 3vh;
            flex-direction: row;
            flex-wrap: wrap;
            width: 350px;
            justify-content: center;
            align-items: center;

            .break {
                flex-basis: 100%;
                height: 0;
            }

            .anagramLetter {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: fit-content;
                flex-basis: 17%;

                &.fade {
                    opacity: 0.25;
                }

                .wordTile {
                    height: auto;
                    width: 70px;
                }

                .letter {
                    font-size: 50px;
                    font-family: "Lalezar";
                    color: black;
                    line-height: 1;
                    text-align: center;
                    position: absolute;
                    width: fit-content;
                    margin: auto;
                    top: 0;
                    bottom: 10px;
                    right: 0;
                    left: 0;
                    height: fit-content;
                    text-transform: capitalize;
                }
            }
        }

        .answerLetters {
            display: flex;
            margin-top: 3vh;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            min-width: 280px;
            min-height: 70px;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 10px;
            padding-left: 10px;
            padding-right: 10px;


            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: 10px;
            }

            .answerLetter {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: fit-content;
                transform: translateY(3px);

                .wordTile {
                    height: auto;
                    width: 70px;
                }

                .letter {
                    font-size: 50px;
                    font-family: "Lalezar";
                    color: black;
                    line-height: 1;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
        }
    }

    .button {
        position: relative;
        font-size: 35px;
        font-family: "Lalezar";
        padding-bottom: 10px;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 10px;
        margin-top: 3vh;
        line-height: 1;
        background-color: transparent;
        color: black;
        border: none;
        text-transform: uppercase;
        z-index: 1;

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .timerSection {
        position: absolute;
        width: min(40vh, 80vw);
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 0;
        gap: 1vh;
        touch-action: none;
        user-select: none;

        &.show {
            transform: translateY(0%);
        }

        .clockBg {
            position: absolute;
            width: 100%;
            height: auto;
            margin: auto;
            left: 50%;
            bottom: 0;
            transform: translatex(-50%);
        }

        .timerText {
            text-align: center;
            font-size: 7vh;
            font-family: "Lalezar";
            color: white;
            width: 25%;
            height: fit-content;
            line-height: 1;
            margin-top: 20%;
            text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
        }

        .clock {
            position: relative;
            width: 20%;
            height: auto;
            margin-top: 17%;

            .clockImg {
                width: 100%;
                height: auto;
            }

            .clockHand {
                position: absolute;
                width: 14%;
                height: auto;
                margin: auto;
                left: 0;
                right: 0;
                top: 25.5%;
                animation: spin 4s linear;
                animation-iteration-count: infinite;
                transform-origin: 47% 87%;
                animation: spin 5s linear infinite;

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}


