.defaultSection {
    --button-bot: rgba(253,195,30,1);
    --button-top: rgba(255,230,121,1);
    --alt-button-bot: #8D96D1;
    --alt-button-top: #BDF2F1;
    --button-boarder: #0E5E87;
    --checkbox-hover: #b9d7ed;
    --box-shadows: rgba(29,118,158,1);
}

.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .playerSection {
        height: fit-content;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .potato {
            position: relative;
            height: min(80vw, 40vh);
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
            bottom: 0;
        }

        .text {
            font-size: min(9vw,7vh);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: black;
            font-family: "Lalezar";
            background-color: white;
            border-radius: min(2vw, 1vh);
            box-shadow: 0 min(2vw, 1vh) 0 0 var(--box-shadows);
            padding-left: min(3vw, 1.5vh);
            padding-right: min(3vw, 1.5vh);
            padding-top: min(2.4vw, 1.2vh);
            padding-bottom: 0;
            /*min-width: 100%;*/
            width: fit-content;
            line-height: 1;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        gap: min(5vh, 25px);
        margin-bottom: 15px;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: white;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                border: 0.3vh solid var(--button-boarder);

                &:hover {
                    background-color: #b9d7ed !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                font-size: 3vh;
                line-height: 3vh;
                height: 3.5vh;
                padding: 0.5vh;
                padding-left: 1vh;
                font-family: 'Lalezar';
                color: white;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
                text-shadow: 0.25vh 0.25vh 0.25vh var(--box-shadows);
            }
        }
    }

    .button {
        font-size: 5vh;
        text-align: center;
        line-height: 5vh;
        height: 7vh;
        color: black;
        //height: fit-content;
        width: fit-content;
        padding: 1.5vh;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Lalezar';
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);
        background: linear-gradient(0deg, var(--button-bot) 0%, var(--button-top) 100%);
        box-shadow: 0 0 0 0.4vh #ffffff, 0 0 0 0.8vh var(--button-boarder);
        border-radius: 2vh;
        min-width: 29vh;

        &.alt {
            background: linear-gradient(0deg, var(--alt-button-bot) 0%, var(--alt-button-top) 100%);
        }
    }
}
