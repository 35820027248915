:root {
    --button-bot: rgba(253,195,30,1);
    --button-top: rgba(255,230,121,1);
    --alt-button-bot: rgba(78, 173, 254, 1);
    --alt-button-top: rgba(6,237,254,1);
    --button-border: #0E5E87;
    --checkbox-hover: #b9d7ed;
    --box-shadows: rgba(30,120,160,1);
}

:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transform-origin: top left;
    z-index: 0;

    &.topLeft {
        transform: scale(0.65);
        right: unset;
        bottom: unset;
    }

    .logo {
        height: 35vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #36C2FE;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background: linear-gradient(30deg, rgba(24,221,254,1) 0%, rgba(65,184,254,1) 100%);
    /*    background-image: url('../../images/Host/anagram-magic-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    overflow: hidden;
    z-index: 0;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        z-index: 12;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Lalezar";
                text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
                color: white;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Lalezar";
                    /*text-shadow: 0.25vh 0.5vh black;*/
                    text-shadow: none;
                    font-size: 4vh;
                    font-weight: normal;
                    color: #002026;
                    line-height: 1;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: min(40vh, 80vw);
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 0;
        gap: 1vh;
        touch-action: none;
        user-select: none;

        &.show {
            transform: translateY(0%);
        }

        .clockBg {
            position: absolute;
            width: 100%;
            height: auto;
            margin: auto;
            left: 50%;
            bottom: 0;
            transform: translatex(-50%);
        }

        .timerText {
            text-align: center;
            font-size: 7vh;
            font-family: "Lalezar";
            color: white;
            width: 25%;
            height: fit-content;
            line-height: 1;
            margin-top: 20%;
            text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
        }

        .clock {
            position: relative;
            width: 20%;
            height: auto;
            margin-top: 17%;

            .clockImg {
                width: 100%;
                height: auto;
            }

            .clockHand {
                position: absolute;
                width: 14%;
                height: auto;
                margin: auto;
                left: 0;
                right: 0;
                top: 25.5%;
                animation: spin 4s linear;
                animation-iteration-count: infinite;
                transform-origin: 47% 87%;
                animation: spin 5s linear infinite;

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }


    .mainButton {
        position: relative;
        font-size: min(10vw,10vh);
        line-height: min(10vw,10vh);
        height: min(14.5vw,14.5vh);
        font-family: "Lalezar";
        padding: min(3vw, 3vh);
        margin: min(1vw, 1vh);
        line-height: 1;
        background: linear-gradient(0deg, var(--button-bot) 0%, var(--button-top) 100%);
        box-shadow: 0 0 0 min(0.4vw, 0.4vh) #ffffff, 0 0 0 min(0.8vw, 0.8vh) var(--button-boarder);
        border-radius: min(2vw, 2vh);
        color: black;
        border: none;

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        &.smaller {
            background: linear-gradient(0deg, var(--alt-button-bot) 0%, var(--alt-button-top) 100%);
            font-size: min(6vw, 6vh);
            line-height: min(5.5vw, 5.5vh);
            height: min(11vw, 11vh);
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;
        gap: min(1vw, 1vh);

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: white;
                margin: 0;
                font: inherit;
                width: min(7.5vw, 7.5vh);
                height: min(7.5vw, 7.5vh);
                border-radius: min(2vw, 2vh);
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                border: min(0.5vw, 0.5vh) solid var(--button-boarder);

                &:hover {
                    background-color: var(--checkbox-hover) !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: min(5.5vw, 5.5vh);
                height: min(5.5vw, 5.5vh);
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                font-size: min(5.5vw, 5.5vh);
                line-height: min(5.5vw, 5.5vh);
                height: min(6vw, 6vh);
                padding: min(0.5vw, 0.5vh);
                padding-left: min(2vw, 2vh);
                border-radius: min(2vw, 2vh);
                margin: auto;
                font-family: 'Lalezar';
                text-shadow: min(0.25vw, 0.25vh) min(0.5vw, 0.5vh) var(--box-shadows);
                color: white;
                line-height: 1;
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 2s;
        padding: 0 5px;


        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }
        }
    }

    .roundNumberSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        user-select: none;
        touch-action: none;

        .roundTextContainer {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: scale(0);
            transition: 0.75s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                /*opacity: 1;*/
                transform: scale(1);
                transition: 0.75s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
            }

            .roundText {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 10vh;
                font-weight: 800;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                .gradiant {
                    color: #ffffff;
                    background-image: -webkit-linear-gradient(90deg, #a2f7fe 20%, #f5ffff 80%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    filter: drop-shadow(0.1vh 0.2vh 0.1vh #166a92) drop-shadow(0.1vh 0.4vh 0.1vh #166a92) drop-shadow(0.1vh 0.6vh 0px #166a92) drop-shadow(-0.3vh 0.2vh 0.1vh #166a92);
                    margin: 0;

                    &.gold {
                        background-image: -webkit-linear-gradient(90deg, #fdc31f 20%, #ffe498 80%);
                    }
                }

                .circle {
                    background-color: #a2f7fe;
                    background: linear-gradient(0deg, #a2f7fe 40%, #f5ffff 100%);
                    width: 13vh;
                    height: 13vh;
                    border-radius: 50%;
                    float: right;
                    margin-left: 2vh;
                    box-shadow: 0vh 0.6vh 0.3vh 0.4vh #166a92;

                    &.gold {
                        background: linear-gradient(0deg, #fdc31f 40%, #ffe498 100%);
                    }

                    .innerCircle {
                        position: relative;
                        background-color: #ffcf3b;
                        border-radius: 50%;
                        width: calc(100% - 2vh);
                        height: calc(100% - 2vh);
                        margin: 1vh;
                        box-shadow: 0px 0.4vh 0.1vh 0.4vh #166a92 inset, 0px 0.6vh 0.3vh 0.8vh rgba(0, 0, 0, 0.2) inset;

                        .number {
                            font-family: "UbuntuMonoBold";
                            margin: 0;
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            //padding-bottom: 0.4vh;
                            color: #166a92;
                        }
                    }
                }
            }
        }

        .roundNumber {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 45vh;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(180deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    .raysContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -5;
        transform: scale(0);
        transition: 0.5s ease-out transform;

        &.show {
            /*opacity: 1;*/
            transform: scale(1.2);
        }

        .rays {
            position: absolute;
            //width: calc(100% + 100vw);
            width: 100vw;
            top: 0;
            left: calc(-50vw + 50%);
            right: 0;
            bottom: 0;
            margin: auto;
            animation: spin 10s linear;
            animation-iteration-count: infinite;
        }
    }

    .anagramSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        user-select: none;
        touch-action: none;
        transform: scale(0.9);

        .infoBox {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: min(3vw, 3vh);
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: min(1vw, 1vh);
            }

            .innerText {
                font-size: min(5vw, 5vh);
                font-family: 'Lalezar';
                color: black;
                text-align: center;
                line-height: 1;
                padding-top: min(0.5vw, 0.5vh);
            }

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }

        .anagram {
            display: flex;
            margin-top: min(5vw, 5vh);
            flex-direction: row;
            flex-wrap: wrap;
            width: min(90vw, 90vh);
            justify-content: center;
            align-items: center;
            gap: min(2.5vw, 2.5vh);

            .anagramLetter {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                min-width: min(13vh, 13vw);
                width: fit-content;
                height: min(13vh, 13vw);
                //padding: min(1vw, 1vh);
                background-color: #fffdf5;
                border-radius: 1.5vh;
                box-shadow: 0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.1vw, 0.1vh) #207ba3, //inner medium blue
                0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.6vw, 0.6vh) #0e5e87, //outer dark blue
                0px max(-0.5vw, -0.5vh) min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) #abdbe8 inset; //inset blue glow
                transform: scale(0);
                transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                &.show {
                    transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                    transform: scale(1);
                }

                .letterText {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: min(9.5vw, 9.5vh);
                    line-height: 0;
                    padding: min(7vw, 7vh) min(1vw, 1vh);
                    margin: auto;
                    font-family: "Lalezar";
                    color: #002026;
                    z-index: 1;
                }

                &.gold {
                    box-shadow: 0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.1vw, 0.1vh) rgba(253,195,30,1), //inner medium blue
                    0px min(1vw, 1vh) min(0.3vw, 0.3vh) min(0.6vw, 0.6vh) rgb(216, 166, 23), //outer dark blue
                    0px max(-0.5vw, -0.5vh) min(0.5vw, 0.5vh) min(0.3vw, 0.3vh) #ffe6a0 inset; //inset blue glow
                }
            }
        }

        .playerSelecting {
            position: absolute;
            margin: auto;
            left: 0;
            right: 50vh;
            bottom: 0;
            opacity: 0;
            transform: translateY(25vh);
            transition: 0.5s transform ease-in-out, 0.5s opacity ease-in-out;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &.show {
                transform: translateY(0vh);
                opacity: 1;
            }

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 25vh;
                width: auto;
            }

            .textSection {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 25vh;

                .letterCallout {
                    position: absolute;
                    color: white;
                    text-shadow: 0.25vh 0.25vh 0.75vh rgba(33,46,60,0.85);
                    font-size: 5vh;
                    font-family: "Lalezar";
                    margin: auto;
                    text-align: center;
                    left: 0;
                    transform: translateY(6vh);
                    opacity: 1;
                    animation: pointsAnim 2s ease-in-out forwards;
                    z-index: 5;
                }

                @keyframes pointsAnim {
                    0% {
                        transform: translate(0, 6vh);
                        opacity: 1;
                    }

                    100% {
                        transform: translate(var(--offset), -2vh);
                        opacity: 0;
                    }
                }
                /*.answerBox {
                    width: fit-content;
                    height: fit-content;
                    transform: scale(0) rotate(90deg);
                    transition: all 0.5s ease-in;
                    transform-origin: bottom left;
                    display: flex;
                    flex-direction: row;
                    background: white;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.7vh 0 0 #1e78a0;
                    height: 6vh;
                    min-width: 5vh;
                    padding-left: 2vh;
                    padding-right: 2vh;

                    &.show {
                        transform: scale(1) rotate(0deg);
                    }

                    .answerText {
                        font-family: "Lalezar";
                        font-size: 4.5vh;
                        color: black;
                        width: fit-content;
                        text-align: center;
                        z-index: 1;
                        text-transform: uppercase;
                    }

                    .arrow {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 2.4vh solid white;
                        border-right: 2.4vh solid transparent;
                        bottom: -2vh;
                        left: 0;
                        border-bottom-left-radius: 0.5vh;
                        border-bottom-right-radius: 0.5vh;

                        &.bg {
                            border-top: 2.75vh solid #1e78a0;
                            border-right: 2.75vh solid transparent;
                            bottom: -2.75vh;
                        }
                    }
                }*/
            }
        }

        .podium {
            display: inline-flex;
            width: 50%;
            height: min(35vw, 35vh);
            //gap: 2vh;
            justify-content: center;
            z-index: 1;

            .potato:nth-child(1) {
                .name {
                    background-color: #C0C0C0;
                    box-shadow: 0 0.5vh 0 0 #71706e;
                }
            }

            .potato:nth-child(3) {
                .name {
                    background-color: #CD7F32;
                    box-shadow: 0 0.5vh 0 0 #804A00;
                }
            }

            .raise {
                animation: raise 0.5s ease-out;
            }

            .hide {
                animation: hide 0.5s ease-in forwards;
            }

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: auto;
                width: min(25vw, 25vh);
                top: min(6vw, 6vh);

                &.first {
                    width: min(35vw, 35vh);
                    top: 0;
                }

                .nameBox {
                    width: fit-content;
                    line-height: 1;
                    position: absolute;
                    margin: auto;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .place {
                    position: relative;
                    width: fit-content;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: -3px;

                    .name {
                        border-radius: 0 0 1vh 1vh;
                    }
                }

                .name {
                    font-family: "Lalezar";
                    z-index: 1;
                    font-size: 4vh;
                    color: black;
                    background-color: #FDC31F;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.5vh 0 0 #D87715;
                    padding-left: 1vh;
                    padding-right: 1vh;
                    padding-top: 0.7vh;
                    padding-bottom: 0vh;
                    text-transform: uppercase;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            @keyframes raise {
                0% {
                    transform: translateY(100vh);
                }

                40% {
                    transform: translateY(0) scale(1.1);
                }

                60% {
                    transform: scale(1) rotate(5deg);
                }

                80% {
                    transform: scale(1.05) rotate(-5deg);
                }

                100% {
                    transform: scale(1) rotate(0);
                }
            }

            @keyframes hide {
                0% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(100vh);
                }
            }
        }

        .bonusAnswer {
            position: absolute;
            width: fit-content;
            top: 25vh;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;
            z-index: -1;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .bonusAnswerBox {
                height: 10vh;
                padding: 2vh;
                background-color: #fffdf5;
                border-radius: 1.5vh;
                box-shadow: 0px 1vh 0.3vh 0.1vh #207ba3, //inner medium blue
                0px 1vh 0.3vh 0.6vh #0e5e87, //outer dark blue
                0px -0.5vh 0.5vh 0.3vh #abdbe8 inset; //inset blue glow

                .bonusAnswerText {
                    font-size: 10vh;
                    line-height: 8vh;
                    padding: 0;
                    margin: 0;
                    font-family: "Lalezar";
                }
            }
        }
    }

    .winnerSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90vh;
        height: 100%;
        user-select: none;
        touch-action: none;
        transform: scale(0);
        transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;
        z-index: -1;

        &.show {
            transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
            transform: scale(1);
        }

        .winnersTitle {
            position: absolute;
            width: 50%;
            height: auto;
            top: 5%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
        }

        .winnerList {
            gap: 5vh;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .winner {
            position: relative;
            height: fit-content;
            flex-basis: 0;

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: auto;
                width: 100%;

                .winnerOverlay {
                    position: absolute;
                    width: 150%;
                    height: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: auto;
                    /*top: 0;*/
                    bottom: -5%;
                    z-index: -1;
                }

                .nameBox {
                    width: fit-content;
                    line-height: 1;
                    position: absolute;
                    //margin: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    min-width: 67.5%;
                    /*max-width: 67.5%;*/
                }

                .name {
                    font-family: "Lalezar";
                    z-index: 1;
                    font-size: 4vh;
                    color: black;
                    /*background-image: url('../images/Host/Potato name bar.png');*/
                    background-color: #FDC31F;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.5vh 0 0 #D87715;
                    padding-left: 1vh;
                    padding-right: 1vh;
                    padding-top: 0.65vh;
                    padding-bottom: 0.4vh;
                    /*min-width: 100%;*/
                    text-transform: uppercase;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &.large {
                max-width: 40vh;
                flex: 1 0 51%;

                .name {
                    font-size: 5.5vh;
                }
            }

            &.medium {
                max-width: 28vh;
                flex: 1 0 34%;

                .name {
                    font-size: 4vh;
                }
            }

            &.small {
                max-width: 22vh;
                flex: 1 0 21%;

                .name {
                    font-size: 2.75vh;
                }
            }
        }
    }

    .gameOverSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        touch-action: none;

        .longestWordsSection {
            position: relative;
            width: 55%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transform: scale(0);
            transition: 1s transform ease-in;

            &.show {
                transform: scale(1);
            }

            .title {
                width: 40vh;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                transform: translateY(4vh);
                z-index: 1;
            }

            .wordList {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                height: fit-content;
                margin-left: auto;
                margin-right: auto;
                flex-wrap: wrap;
                width: 60vh;
                column-gap: 5vh;
                bottom: 0;

                .wordRow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    max-width: 20vh;

                    .potato {
                        position: relative;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: auto;
                        width: 100%;
                        transform: translateY(3.75vh);
                    }

                    .nameBox {
                        position: relative;
                        width: 15vh;
                        height: fit-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: auto;

                        .nameBanner {
                            position: absolute;
                            width: auto;
                            height: 100%;
                            transform: translateX(1vh);
                        }

                        .name {
                            font-family: "Lalezar";
                            z-index: 1;
                            font-size: 2.5vh;
                            color: black;
                            border-radius: 0.5vh;
                            padding-top: 0.3vh;
                            text-transform: uppercase;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }

                    .word {
                        font-family: "Lalezar";
                        z-index: 1;
                        font-size: 3vh;
                        border-radius: 0.5vh;
                        text-transform: uppercase;
                        text-align: center;
                        text-shadow: 0.25vh 0.25vh #212e3c;
                        color: white;
                        line-height: 1;
                        margin-top: 1vh;
                        min-width: 25vh;
                        max-width: 25vh;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .leaderboardSection {
            position: relative;
            width: 45%;
            height: fit-content;
            margin: auto;
            justify-content: start;
            align-items: start;
            display: flex;
            flex-direction: column;
            transition: 0.75s transform;
            transform: translateX(50vw);
            /*top: 5%;*/
            /*right: 5%;*/
            gap: 2vh;

            &.show {
                transform: translateX(0%);
                opacity: 1;
            }


            .tableRow {
                position: relative;
                font-family: "Lalezar";
                font-size: 4vh;
                display: flex;
                flex-direction: row;
                width: 90%;
                align-items: center;
                padding-right: 6vh;
                padding-left: 2vh;
                padding-top: 0.75vh;
                padding-bottom: 0.75vh;
                background-color: rgba(0, 32, 38, 0.25);
                border-radius: 2.5vh;
                text-transform: uppercase;
                line-height: 1;

                &.header {
                    background-color: unset;
                    text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
                    color: white;

                    .position {
                        border: none !important;
                    }

                    .score {
                        border: none !important;
                    }
                }
            }

            .position {
                text-align: start;
                width: 15%;
                transform: translateY(0.4vh);
            }

            .name {
                width: 65%;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                transform: translateY(0.4vh);
            }

            .score {
                width: 15%;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
                transform: translateY(0.4vh);

                .smaller {
                    text-transform: lowercase !important;
                }
            }

            .potato {
                position: absolute;
                width: auto;
                height: 9.5vh;
                margin: auto;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
    }

    .playAgainButtonSection {
        width: 55%;
        height: 20%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: min(4vw, 4vh);
            font-family: "Lalezar";
            padding-bottom: min(1.2vw, 1.2vh);
            padding-top: min(2vw, 2vh);
            padding-left: min(3vw, 3vh);
            padding-right: min(3vw, 3vh);
            margin: min(2vw, 2vh);
            line-height: 1;
            color: black;
            border: none;
            text-transform: uppercase;
            z-index: 1;
            background: linear-gradient(0deg, var(--button-bot) 0%, var(--button-top) 100%);
            box-shadow: 0 0 0 min(0.4vw, 0.4vh) #ffffff, 0 0 0 min(0.8vw, 0.8vh) var(--button-boarder);
            border-radius: min(1.5vw, 1.5vh);

            &.alt {
                background: linear-gradient(0deg, var(--alt-button-bot) 0%, var(--alt-button-top) 100%);
            }

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
