[contenteditable] {
    outline: 0px solid transparent;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

/*:global(body) {
    background: rgb(161,140,209);
    background: linear-gradient(30deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
    height: 100vh;
    width: 100vw;
}*/

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: linear-gradient(30deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 5vh;
            text-align: center;
            line-height: 5vh;
            height: 7vh;
            color: black;
            //height: fit-content;
            width: fit-content;
            padding: 1.5vh;
            margin-left: auto;
            margin-right: auto;
            font-family: 'Lalezar';
            user-select: inherit !important;
            cursor: pointer;
            transform: scale(1);
            background: linear-gradient(0deg, rgba(253,195,30,1) 0%, rgba(255,230,121,1) 100%);
            box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #703499;
            border-radius: 2vh;
            min-width: 29vh;

            &.alt {
                background: linear-gradient(0deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
            }
        }
    }


    .answerSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .infoBox {
            width: fit-content;
            height: fit-content;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            min-height: 55px;


            &.hide {
                opacity: 0;
            }

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #002026;
                opacity: 0.15;
                border-radius: 10px;
            }

            .innerText {
                font-size: 25px;
                font-family: 'Lalezar';
                color: black;
                text-align: center;
                line-height: 1;
            }
        }


        .answerLetters {
            display: flex;
            margin-top: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            min-width: 280px;
            min-height: 70px;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 10px;
            padding-left: 10px;
            padding-right: 10px;
            transition: transform linear 0.25s;

            &.valid {
                animation: pulse 0.3s;

                .letter {
                    color: #f5c834;
                    animation: fade 1s forwards;
                }
            }

            @keyframes pulse {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.1);
                }

                100% {
                    transform: scale(1);
                }
            }

            @keyframes fade {
                0% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }

            &.invalid {
                animation: shake 0.2s;
                animation-iteration-count: 2;

                .letter {
                    color: red;
                    animation: fade 1s forwards;
                }
            }

            @keyframes shake {
                0% {
                    transform: translate(1px, 1px) rotate(0deg);
                }

                10% {
                    transform: translate(-1px, -2px) rotate(-1deg);
                }

                20% {
                    transform: translate(-3px, 0px) rotate(1deg);
                }

                30% {
                    transform: translate(3px, 2px) rotate(0deg);
                }

                40% {
                    transform: translate(1px, -1px) rotate(1deg);
                }

                50% {
                    transform: translate(-1px, 2px) rotate(-1deg);
                }

                60% {
                    transform: translate(-3px, 1px) rotate(0deg);
                }

                70% {
                    transform: translate(3px, 1px) rotate(-1deg);
                }

                80% {
                    transform: translate(-1px, -1px) rotate(1deg);
                }

                90% {
                    transform: translate(1px, 2px) rotate(0deg);
                }

                100% {
                    transform: translate(1px, -2px) rotate(-1deg);
                }
            }
        }


        .background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #002026;
            opacity: 0.15;
            border-radius: 10px;
        }

        .wordValidationImg {
            position: absolute;
            width: 20%;
            height: auto;
            margin: auto;
            right: -8%;
            top: -30%;
            z-index: 1;
            transform: scale(0);

            @keyframes show {
                0% {
                    transform: scale(0);
                }

                25% {
                    transform: scale(1);
                }

                75% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0);
                }
            }

            &.show {
                animation: show 2s ease-in-out 0s 1 normal forwards;
            }
        }

        .answerLetter {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: fit-content;
            transform: translateY(3px);

            .wordTile {
                height: auto;
                width: 70px;
            }

            .letter {
                font-size: 50px;
                font-family: "Lalezar";
                color: black;
                line-height: 1;
                text-align: center;
                /*position: absolute;*/
                /* width: fit-content;
                    margin: auto;
                    top: 0;
                    bottom: 0px;
                    right: 0;
                    left: 0;
                    height: fit-content;*/
                text-transform: capitalize;
                transition: color linear 0.2s;
            }
        }
    }

    .wheel {
        position: relative;
        width: fit-content;
        height: fit-content;
        margin-top: 30px;

        .wheelBg {
            position: absolute;
            width: 115%;
            height: 115%;
            margin: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .wheelContainer {
        position: relative;
        width: 325px;
        height: 325px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        /*background-color: #eee;*/
        /*opacity: 0.5;*/
        transform: translateY(-2.5%)
    }

    .segment {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        height: 50%;
        transform-origin: 50% 100%;
        clip-path: polygon(10% 0%, 50% 100%, 90% 0%);
        /*cursor: pointer;*/
        line-height: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        padding-bottom: 20%;


        .segmentText {
            text-align: center;
            font-family: "Lalezar";
            font-size: 50px;
            color: #002026;
            text-transform: capitalize;
            transition: transform linear 0.25s, color linear 0.25s;

            &.selected {
                opacity: 0.5;
            }

            &.valid {
                animation: pulse 0.3s;
                color: #f5c834;
                animation-composition: add;
            }

            &.invalid {
                animation: shake 0.2s;
                animation-iteration-count: 2;
                color: red;
                animation-composition: add;
            }
        }
    }
    /* Style the individual slices with clip-path */
    .segment:nth-child(1) {
        /*background-color: #f00;*/
        transform: rotate(0deg);

        .segmentText {
            transform: rotate(0deg);
        }
    }

    .segment:nth-child(2) {
        /*background-color: #0f0;*/
        transform: rotate(45deg);

        .segmentText {
            transform: rotate(-45deg);
        }
    }

    .segment:nth-child(3) {
        /*background-color: #00f;*/
        transform: rotate(90deg);

        .segmentText {
            transform: rotate(-90deg);
        }
    }

    .segment:nth-child(4) {
        /*background-color: #ff0;*/
        transform: rotate(135deg);

        .segmentText {
            transform: rotate(-135deg);
        }
    }

    .segment:nth-child(5) {
        /*background-color: #0ff;*/
        transform: rotate(180deg);

        .segmentText {
            transform: rotate(-180deg);
        }
    }

    .segment:nth-child(6) {
        /*background-color: #f0f;*/
        transform: rotate(225deg);

        .segmentText {
            transform: rotate(-225deg);
        }
    }

    .segment:nth-child(7) {
        /*background-color: #fff;*/
        transform: rotate(270deg);

        .segmentText {
            transform: rotate(-270deg);
        }
    }

    .segment:nth-child(8) {
        /*background-color: #000;*/
        transform: rotate(315deg);

        .segmentText {
            transform: rotate(-315deg);
        }
    }
    /* Style the center circle */
    .centerCircle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 4%;
        margin: auto;
        width: 32.5%;
        height: 32.5%;
        /*background-color: #333;*/
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-family: "Lalezar";
        font-size: 50px;
        color: #002026;
        text-transform: capitalize;
        transition: transform linear 0.25s, color linear 0.25s;

        &.selected {
            opacity: 0.5;
        }

        &.valid {
            animation: pulse 0.3s;
            color: #f5c834;
        }

        &.invalid {
            animation: shake 0.2s;
            animation-iteration-count: 2;
            color: red;
        }
    }


    .button {
        position: relative;
        font-size: 35px;
        font-family: "Lalezar";
        padding-bottom: 10px;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 10px;
        line-height: 1;
        background-color: transparent;
        color: black;
        border: none;
        text-transform: uppercase;
        z-index: 1;

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        &:disabled {
            opacity: 0.5;
        }
    }
}


