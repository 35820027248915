
.clientMenuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1000;
    touch-action: none;
    pointer-events: none;

    .clientMenuIcon {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
        touch-action: initial;
        pointer-events: initial;
    }
}
