div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transform-origin: top left;
    z-index:1;

    &.topLeft {
        transform: scale(0.65);
        right: unset;
        bottom: unset;
    }

    .logo {
        height: 35vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #36C2FE;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background: linear-gradient(30deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
    /*background: #000000;*/
    /*    background-image: url('../../images/Host/word-wheel-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    overflow: hidden;
    z-index: 0;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        z-index: 12;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Lalezar";
                color: #561964;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Lalezar";
                    /*text-shadow: 0.25vh 0.5vh black;*/
                    text-shadow: none;
                    font-size: 4vh;
                    font-weight: normal;
                    color: #002026;
                    line-height: 1;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: min(40vh, 80vw);
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 0;
        gap: 1vh;
        touch-action: none;
        user-select: none;

        &.show {
            transform: translateY(0%);
        }

        .clockBg {
            position: absolute;
            width: 100%;
            height: auto;
            margin: auto;
            left: 50%;
            bottom: 0;
            transform: translatex(-50%);
        }

        .timerText {
            text-align: center;
            font-size: 7vh;
            font-family: "Lalezar";
            color: white;
            width: 25%;
            height: fit-content;
            line-height: 1;
            margin-top: 20%;
            text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
        }

        .clock {
            position: relative;
            width: 20%;
            height: auto;
            margin-top: 17%;

            .clockImg {
                width: 100%;
                height: auto;
            }

            .clockHand {
                position: absolute;
                width: 14%;
                height: auto;
                margin: auto;
                left: 0;
                right: 0;
                top: 25.5%;
                animation: spin 4s linear;
                animation-iteration-count: infinite;
                transform-origin: 47% 87%;
                animation: spin 5s linear infinite;

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .mainButton {
        position: relative;
        font-size: 10vh;
        font-family: "Lalezar";
        padding-bottom: 2.5vh;
        padding-top: 2.75vh;
        padding-left: 5vh;
        padding-right: 5vh;
        margin: 2vh;
        line-height: 1;
        border: none;
        color: black;
        background: linear-gradient(0deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
        box-shadow: 0 0 0 0.5vh #ffffff, 0 0 0 1vh #703499;
        border-radius: 2vh;

        &.alt {
            background: linear-gradient(0deg, rgba(253,195,30,1) 0%, rgba(255,230,121,1) 100%);
        }

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        &.smaller {
            /*background-image: url('../images/Host/Button BG_2.png');*/
            font-size: 6vh;
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: white;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                border: 0.5vh solid #561964;

                &:hover {
                    background-color: lightgrey !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: 'Lalezar';
                text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
                color: white;
                line-height: 1;
                margin: unset;
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 2s;
        padding: 0 5px;

        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }
        }
    }

    .roundNumberSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        user-select: none;
        touch-action: none;

        .roundTextContainer {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: scale(0);
            transition: 0.75s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                /*opacity: 1;*/
                transform: scale(1);
                transition: 0.75s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
            }

            .roundText {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 10vh;
                font-weight: 800;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                .gradiant {
                    color: #ffffff;
                    background-image: -webkit-linear-gradient(90deg, #D0B2D6 20%, #ffffff 80%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    filter: drop-shadow(0.1vh 0.2vh 0.1vh #74359B) drop-shadow(0.1vh 0.4vh 0.1vh #74359B) drop-shadow(0.1vh 0.6vh 0px #74359B) drop-shadow(-0.3vh 0.2vh 0.1vh #74359B);
                    margin: 0;
                }

                .circle {
                    background-color: #a2f7fe;
                    background: linear-gradient(0deg, #D0B2D6 40%, #ffffff 100%);
                    width: 13vh;
                    height: 13vh;
                    border-radius: 50%;
                    float: right;
                    margin-left: 2vh;
                    box-shadow: 0vh 0.6vh 0.3vh 0.4vh #74359B;

                    .innerCircle {
                        position: relative;
                        background-color: #ffcf3b;
                        border-radius: 50%;
                        width: calc(100% - 2vh);
                        height: calc(100% - 2vh);
                        margin: 1vh;
                        box-shadow: 0px 0.4vh 0.1vh 0.4vh #74359B inset, 0px 0.6vh 0.3vh 0.8vh rgba(0, 0, 0, 0.2) inset;

                        .number {
                            font-family: "UbuntuMonoBold";
                            margin: 0;
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            //padding-bottom: 0.4vh;
                            color: #74359B;
                        }
                    }
                }
            }
        }

        .roundNumber {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 45vh;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(180deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    .raysContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -5;
        transform: scale(0);
        transition: 0.5s ease-out transform;

        &.show {
            /*opacity: 1;*/
            transform: scale(1.2);
        }

        .rays {
            position: absolute;
            //width: calc(100% + 100vw);
            width: 100vw;
            top: 0;
            left: calc(-50vw + 50%);
            right: 0;
            bottom: 0;
            margin: auto;
            animation: spin 10s linear;
            animation-iteration-count: infinite;
        }
    }

    .wheelSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        user-select: none;
        touch-action: none;
        transform: scale(0.9);

        .rays {
            position:absolute;
            width: 100vw;
            z-index: -5;
        }

        .infoBox {
            width: fit-content;
            height: fit-content;
            padding: 3vh;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #1f0026;
                opacity: 0.15;
                border-radius: 1vh;
            }

            .innerText {
                font-size: 5vh;
                font-family: 'Lalezar';
                color: #002026;
                text-align: center;
                line-height: 1;
            }
        }

        .bar {
            position: relative;
            width: 60vh;
            height: fit-content;

            .barFront {
                position: absolute;
                width: 100%;
                height: auto;
            }

            .barFillContainer {
                display: flex;
                padding-top: 1vh;
                padding-left: 2.5vh;
                padding-right: 3vh;
                width: 100%;
                height: 7vh;

                .fill {
                    width: 100%;
                    height: 100%;
                    border-radius: 5vh;
                    background-color: #ffdb2d;
                }
            }
        }

        .wheel {
            position: relative;
            width: fit-content;
            height: fit-content;
            margin-top: 5vh;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .wheelBg {
                position: absolute;
                width: 115%;
                height: 115%;
                margin: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .wheelContainer {
            position: relative;
            width: 60vh;
            height: 60vh;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;
            /*background-color: #eee;*/
            /*opacity: 0.5;*/
            transform: translateY(-2.5%)
        }

        .segment {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 50%;
            height: 50%;
            transform-origin: 50% 100%;
            clip-path: polygon(10% 0%, 50% 100%, 90% 0%);
            /*cursor: pointer;*/
            line-height: 1;
            justify-content: center;
            align-items: center;
            display: flex;
            padding-bottom: 20%;


            .segmentText {
                text-align: center;
                font-family: "Lalezar";
                font-size: 10vh;
                color: #002026;
                text-transform: uppercase;
            }

            &:hover {
                opacity: 0.5;
            }
        }
        /* Style the individual slices with clip-path */
        .segment:nth-child(1) {
            /*background-color: #f00;*/
            transform: rotate(0deg);

            .segmentText {
                transform: rotate(0deg);
            }
        }

        .segment:nth-child(2) {
            /*background-color: #0f0;*/
            transform: rotate(45deg);

            .segmentText {
                transform: rotate(-45deg);
            }
        }

        .segment:nth-child(3) {
            /*background-color: #00f;*/
            transform: rotate(90deg);

            .segmentText {
                transform: rotate(-90deg);
            }
        }

        .segment:nth-child(4) {
            /*background-color: #ff0;*/
            transform: rotate(135deg);

            .segmentText {
                transform: rotate(-135deg);
            }
        }

        .segment:nth-child(5) {
            /*background-color: #0ff;*/
            transform: rotate(180deg);

            .segmentText {
                transform: rotate(-180deg);
            }
        }

        .segment:nth-child(6) {
            /*background-color: #f0f;*/
            transform: rotate(225deg);

            .segmentText {
                transform: rotate(-225deg);
            }
        }

        .segment:nth-child(7) {
            /*background-color: #fff;*/
            transform: rotate(270deg);

            .segmentText {
                transform: rotate(-270deg);
            }
        }

        .segment:nth-child(8) {
            /*background-color: #000;*/
            transform: rotate(315deg);

            .segmentText {
                transform: rotate(-315deg);
            }
        }
        /* Style the center circle */
        .centerCircle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 4%;
            margin: auto;
            width: 32.5%;
            height: 32.5%;
            /*background-color: #333;*/
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-family: "Lalezar";
            font-size: 10vh;
            color: #002026;
            text-transform: uppercase;
        }
    }

    .roundEndSection {
        position: absolute;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        gap: 7vh;


        .wordList {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: fit-content;
            margin: auto;
            gap: 3vh;
            margin-top: unset;

            .wordListTitle {
                font-size: 7vh;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                color: black;
                font-family: "Lalezar";
                background-color: #FFDA2B;
                border-radius: 1vh;
                box-shadow: 0 1vh 0 0 rgba(112,52,153,1);
                padding-left: 1.5vh;
                padding-right: 1.5vh;
                padding-top: 1.5vh;
                padding-bottom: 1.2vh;
                /*min-width: 100%;*/
                width: fit-content;
                line-height: 1;
                transform: scale(0);
                transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                &.show {
                    transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                    transform: scale(1);
                }
            }

            .word {
                position: relative;
                font-size: 5vh;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                color: black;
                font-family: "Lalezar";
                background-color: white;
                border-radius: 1vh;
                box-shadow: 0 1vh 0 0 rgba(112,52,153,1);
                padding-left: 1.5vh;
                padding-right: 1.5vh;
                padding-top: 1.5vh;
                padding-bottom: 1.2vh;
                /*min-width: 100%;*/
                width: fit-content;
                line-height: 1;
                transform: scale(0);
                transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                &.show {
                    transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                    transform: scale(1);
                }

                .star {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: -100%;
                    top: -10%;
                    width: 5vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        position: absolute;
                        width: 100%;
                    }

                    .count {
                        font-family: "Lalezar";
                        color: black;
                        font-size: 2.75vh;
                        text-align: center;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .winnerSection {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90vh;
        height: 100%;
        user-select: none;
        touch-action: none;

        .winnersTitle {
            position: absolute;
            width: 50%;
            height: auto;
            top: 5%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }

        .winnerList {
            gap: 5vh;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .winner {
            position: relative;
            height: fit-content;
            flex-basis: 0;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: auto;
                width: 100%;

                .winnerOverlay {
                    position: absolute;
                    width: 150%;
                    height: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: auto;
                    /*top: 0;*/
                    bottom: -5%;
                    z-index: -1;
                }

                .nameBox {
                    width: fit-content;
                    line-height: 1;
                    position: absolute;
                    //margin: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    min-width: 67.5%;
                    /*max-width: 67.5%;*/
                }

                .name {
                    font-family: "Lalezar";
                    z-index: 1;
                    font-size: 4vh;
                    color: #002026;
                    /*background-image: url('../images/Host/Potato name bar.png');*/
                    background-color: #FDC31F;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.5vh 0 0 #D87715;
                    padding-left: 1vh;
                    padding-right: 1vh;
                    padding-top: 0.65vh;
                    padding-bottom: 0.4vh;
                    /*min-width: 100%;*/
                    text-transform: uppercase;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &.large {
                max-width: 40vh;
                flex: 1 0 51%;

                .name {
                    font-size: 5.5vh;
                }
            }

            &.medium {
                max-width: 28vh;
                flex: 1 0 34%;

                .name {
                    font-size: 4vh;
                }
            }

            &.small {
                max-width: 22vh;
                flex: 1 0 21%;

                .name {
                    font-size: 2.75vh;
                }
            }
        }
    }

    .gameOverSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        touch-action: none;

        .longestWordsSection {
            position: relative;
            width: 55%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transform: scale(0);
            transition: 1s transform ease-in;

            &.show {
                transform: scale(1);
            }

            .title {
                width: 40vh;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                transform: translateY(4vh);
                z-index: 1;
            }

            .wordList {
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                height: fit-content;
                margin-left: auto;
                margin-right: auto;
                flex-wrap: wrap;
                width: 60vh;
                column-gap: 5vh;

                .wordRow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    max-width: 20vh;

                    .potato {
                        position: relative;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: auto;
                        width: 100%;
                        transform: translateY(3.75vh);
                    }

                    .nameBox {
                        position: relative;
                        width: 15vh;
                        height: fit-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: auto;

                        .nameBanner {
                            position: absolute;
                            width: auto;
                            height: 100%;
                            transform: translateX(1vh);
                        }

                        .name {
                            font-family: "Lalezar";
                            z-index: 1;
                            font-size: 2.5vh;
                            color: black;
                            border-radius: 0.5vh;
                            padding-top: 0.3vh;
                            text-transform: uppercase;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }

                    .word {
                        font-family: "Lalezar";
                        z-index: 1;
                        font-size: 3vh;
                        border-radius: 0.5vh;
                        text-transform: uppercase;
                        text-align: center;
                        text-shadow: 0.25vh 0.25vh #212e3c;
                        color: white;
                        line-height: 1;
                        margin-top: 1vh;
                        min-width: 25vh;
                        max-width: 25vh;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .leaderboardSection {
            position: relative;
            width: 45%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: column;
            transition: 0.75s transform;
            transform: translateX(50vw);
            /*top: 5%;*/
            /*right: 5%;*/
            gap: 2vh;
            opacity: 0;
            justify-content: center;
            align-items: center;

            &.show {
                transform: translateX(0%);
                opacity: 1;
            }


            .tableRow {
                position: relative;
                font-family: "Lalezar";
                font-size: 4vh;
                display: flex;
                flex-direction: row;
                width: 90%;
                align-items: center;
                padding-right: 6vh;
                padding-left: 2vh;
                padding-top: 0.75vh;
                padding-bottom: 0.75vh;
                background-color: rgba(0, 32, 38, 0.25);
                border-radius: 2.5vh;
                text-transform: uppercase;
                line-height: 1;

                &.header {
                    background-color: unset;
                    text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
                    color: white;

                    .position {
                        border: none !important;
                    }

                    .score {
                        border: none !important;
                    }
                }
            }

            .position {
                text-align: start;
                width: 15%;
                transform: translateY(0.4vh);
            }

            .name {
                width: 65%;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                transform: translateY(0.4vh);
            }

            .score {
                width: 15%;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
                transform: translateY(0.4vh);

                .smaller {
                    text-transform: lowercase !important;
                }
            }

            .potato {
                position: absolute;
                width: auto;
                height: 9.5vh;
                margin: auto;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
    }

    .playAgainButtonSection {
        width: 55%;
        height: 20%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: 4vh;
            font-family: "Lalezar";
            padding-bottom: 2vh;
            padding-top: 2vh;
            padding-left: 3vh;
            padding-right: 3vh;
            margin: 2vh;
            line-height: 1;
            border: none;
            color: black;
            text-transform: uppercase;
            z-index: 1;
            background: linear-gradient(0deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
            box-shadow: 0 0 0 0.4vh #ffffff, 0 0 0 0.8vh #703499;
            border-radius: 1.5vh;

            &.alt {
                background: linear-gradient(0deg, rgba(253,195,30,1) 0%, rgba(255,230,121,1) 100%);
            }

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
