div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Lalezar";
        color: black;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        white-space: pre-wrap;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 5vh;
        font-family: "Lalezar";
        color: white;
        text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {
        .playerRow {
            display: flex;
            width: auto;
            height: auto;
            justify-content: center;
            align-items: center;

            .player {
                width: 12.5vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;

                &.show {
                    transform: scale(1);
                }

                .chameleon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(1.15);
                }
            }
        }
    }

    .stepTwo {
        .wheelSection {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            user-select: none;
            touch-action: none;
            transform: scale(0.9);



            .wheel {
                position: relative;
                width: fit-content;
                height: fit-content;
                margin-top: 5vh;
                transform: scale(0);
                transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                &.show {
                    transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                    transform: scale(1);
                }

                .wheelBg {
                    position: absolute;
                    width: 115%;
                    height: 115%;
                    margin: auto;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .wheelContainer {
                position: relative;
                width: 60vh;
                height: 60vh;
                margin: 0 auto;
                border-radius: 50%;
                overflow: hidden;
                /*background-color: #eee;*/
                /*opacity: 0.5;*/
                transform: translateY(-2.5%)
            }

            .segment {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 50%;
                height: 50%;
                transform-origin: 50% 100%;
                clip-path: polygon(10% 0%, 50% 100%, 90% 0%);
                /*cursor: pointer;*/
                line-height: 1;
                justify-content: center;
                align-items: center;
                display: flex;
                padding-bottom: 20%;


                .segmentText {
                    text-align: center;
                    font-family: "Lalezar";
                    font-size: 10vh;
                    color: #002026;
                    text-transform: uppercase;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
            /* Style the individual slices with clip-path */
            .segment:nth-child(1) {
                /*background-color: #f00;*/
                transform: rotate(0deg);

                .segmentText {
                    transform: rotate(0deg);
                }
            }

            .segment:nth-child(2) {
                /*background-color: #0f0;*/
                transform: rotate(45deg);

                .segmentText {
                    transform: rotate(-45deg);
                }
            }

            .segment:nth-child(3) {
                /*background-color: #00f;*/
                transform: rotate(90deg);

                .segmentText {
                    transform: rotate(-90deg);
                }
            }

            .segment:nth-child(4) {
                /*background-color: #ff0;*/
                transform: rotate(135deg);

                .segmentText {
                    transform: rotate(-135deg);
                }
            }

            .segment:nth-child(5) {
                /*background-color: #0ff;*/
                transform: rotate(180deg);

                .segmentText {
                    transform: rotate(-180deg);
                }
            }

            .segment:nth-child(6) {
                /*background-color: #f0f;*/
                transform: rotate(225deg);

                .segmentText {
                    transform: rotate(-225deg);
                }
            }

            .segment:nth-child(7) {
                /*background-color: #fff;*/
                transform: rotate(270deg);

                .segmentText {
                    transform: rotate(-270deg);
                }
            }

            .segment:nth-child(8) {
                /*background-color: #000;*/
                transform: rotate(315deg);

                .segmentText {
                    transform: rotate(-315deg);
                }
            }
            /* Style the center circle */
            .centerCircle {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 4%;
                margin: auto;
                width: 32.5%;
                height: 32.5%;
                /*background-color: #333;*/
                border-radius: 50%;
                text-align: center;
                line-height: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
                font-family: "Lalezar";
                font-size: 10vh;
                color: #002026;
                text-transform: uppercase;
            }
        }
    }

    .stepThree {

        .answerBoxRow {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 3vh;
            justify-content: center;
            align-items: center;
        }

        .answerBox {
            width: fit-content;
            display: flex;
            flex-direction: row;
            background: white;
            border-radius: 0.5vh;
            box-shadow: 0 0.75vh 0 0 rgba(112,52,153,1);
            height: fit-content;
            min-width: 5vh;
            padding-left: 2vh;
            padding-right: 2vh;
            padding-top: 2vh;
            z-index: 1;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .answerText {
                position: relative;
                font-family: "Lalezar";
                font-size: 9vh;
                top: -0.6vh;
                color: black;
                width: fit-content;
                text-align: center;
                z-index: 1;
                text-transform: uppercase;
                line-height: 1;
            }

            .answerValidation {
                position: absolute;
                margin: auto;
                left: 0;
                right: -100%;
                top: -10%;
                width: 5.5vh;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(0);
                opacity: 0;
                transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                &.show {
                    /*opacity: 1;*/
                    transform: scale(1);
                    opacity: 1;
                    transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                }

                img {
                    position: absolute;
                    width: 100%;
                }

                .count {
                    font-family: "Lalezar";
                    color: black;
                    font-size: 2.75vh;
                    text-align: center;
                    z-index: 1;
                }
            }

            .arrow {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 2.4vh solid white;
                border-right: 2.4vh solid transparent;
                bottom: -2vh;
                left: 0;
                border-bottom-left-radius: 0.5vh;
                border-bottom-right-radius: 0.5vh;

                &.bg {
                    border-top: 2.75vh solid rgba(112,52,153,1);
                    border-right: 2.75vh solid transparent;
                    bottom: -2.75vh;
                }
            }
        }
    }

    .stepFour {
        .stepFourBubble {
            width: 50vh;
            margin-left: auto;
            margin-right: auto;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }
    }

    .stepFive {
    }
}
