div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Lalezar";
        color: black;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        white-space: pre-wrap;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 5vh;
        font-family: "Lalezar";
        color: white;
        text-shadow: 0.25vh 0.5vh rgba(33, 46, 60, 0.2);
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {
        .playerRow {
            display: flex;
            width: auto;
            height: auto;
            justify-content: center;
            align-items: center;

            .player {
                width: 12.5vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;

                &.show {
                    transform: scale(1);
                }

                .chameleon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(1.15);
                }
            }
        }
    }

    .stepTwo {
        .anagramSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: fit-content;
            user-select: none;
            touch-action: none;
            transform: scale(0.9);

            .anagram {
                display: flex;
                margin-top: 5vh;
                flex-direction: row;
                flex-wrap: wrap;
                width: 90vh;
                justify-content: center;
                align-items: center;

                .anagramLetter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 20%;
                    height: fit-content;
                    transform: scale(0);
                    transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                    &.show {
                        transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                        transform: scale(1);
                    }

                    .wordTile {
                        height: auto;
                        width: 100%;
                    }

                    .letter {
                        font-size: 12vh;
                        font-family: "Lalezar";
                        color: black;
                        line-height: 1;
                        text-align: center;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 3vh;
                        right: 0;
                        left: 0;
                        height: fit-content;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .stepThree {
        .stepThreeBubble {
            width: 50vh;
            margin-left: auto;
            margin-right: auto;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }
    }

    .stepFour {
        .stepFourBubble {
            width: 50vh;
            margin-left: auto;
            margin-right: auto;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }
        }
    }

    .stepFive {

        .roundTextContainer {
            margin-top: 5vh;
            margin: auto;
            transform: scale(0);
            transition: 0.75s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                /*opacity: 1;*/
                transform: scale(1);
                transition: 0.75s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
            }

            .roundText {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 10vh;
                font-weight: 800;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                .gradiant {
                    color: #ffffff;
                    background-image: -webkit-linear-gradient(90deg, #a2f7fe 20%, #f5ffff 80%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    filter: drop-shadow(0.1vh 0.2vh 0.1vh #166a92) drop-shadow(0.1vh 0.4vh 0.1vh #166a92) drop-shadow(0.1vh 0.6vh 0px #166a92) drop-shadow(-0.3vh 0.2vh 0.1vh #166a92);
                    margin: 0;

                    &.gold {
                        background-image: -webkit-linear-gradient(90deg, #fdc31f 20%, #ffe498 80%);
                    }
                }

                .circle {
                    background-color: #a2f7fe;
                    background: linear-gradient(0deg, #a2f7fe 40%, #f5ffff 100%);
                    width: 13vh;
                    height: 13vh;
                    border-radius: 50%;
                    float: right;
                    margin-left: 2vh;
                    box-shadow: 0px 6px 3px 4px #166a92;

                    &.gold {
                        background: linear-gradient(0deg, #fdc31f 40%, #ffe498 100%);
                    }

                    .innerCircle {
                        position: relative;
                        background-color: #ffcf3b;
                        border-radius: 50%;
                        width: calc(100% - 2vh);
                        height: calc(100% - 2vh);
                        margin: 1vh;
                        box-shadow: 0px 0.4vh 0.1vh 0.4vh #166a92 inset, 0px 0.6vh 0.3vh 0.8vh rgba(0, 0, 0, 0.2) inset;

                        .number {
                            font-family: "UbuntuMonoBold";
                            margin: 0;
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            //padding-bottom: 0.4vh;
                            color: #166a92;
                        }
                    }
                }
            }
        }
    }

    .stepSix {

        .anagramSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: fit-content;
            user-select: none;
            touch-action: none;
            transform: scale(0.9);

            .anagram {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 90vh;
                justify-content: center;
                align-items: center;

                .anagramLetter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 20%;
                    height: fit-content;
                    transform: scale(0);
                    transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

                    &.show {
                        transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                        transform: scale(1);
                    }

                    .wordTile {
                        height: auto;
                        width: 100%;
                    }

                    .letter {
                        font-size: 12vh;
                        font-family: "Lalezar";
                        color: black;
                        line-height: 1;
                        text-align: center;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 3vh;
                        right: 0;
                        left: 0;
                        height: fit-content;
                        text-transform: capitalize;
                    }
                }
            }
        }


        .answer {
            width: fit-content;
            top: 5vh;
            transform: scale(0);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;
            z-index: -1;
            margin-left: auto;
            margin-right: auto;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: scale(1);
            }

            .answerBox {
                height: fit-content;
                padding: 1vh;
                padding-bottom: 0.25vh;
                padding-left: 2vh;
                padding-right: 2vh;
                background-color: #fffdf5;
                border-radius: 1.5vh;
                box-shadow: 0px 1vh 0.3vh 0.1vh #207ba3, //inner medium blue
                0px 1vh 0.3vh 0.6vh #0e5e87, //outer dark blue
                0px -0.5vh 0.5vh 0.3vh #abdbe8 inset; //inset blue glow

                .answerText {
                    font-size: 14vh;
                    line-height: 1;
                    padding: 0;
                    margin: 0;
                    font-family: "Lalezar";
                }
            }
        }
    }

    .stepSeven {
        .podium {
            display: inline-flex;
            width: 50%;
            height: min(35vw, 35vh);
            //gap: 2vh;
            justify-content: center;
            z-index: 1;
            transform: translateY(100vh) scale(1.25);
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;
            margin-left: auto;
            margin-right: auto;

            &.show {
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
                transform: translateY(0vh) scale(1.25);
            }


            .potato:nth-child(1) {
                .name {
                    background-color: #C0C0C0;
                    box-shadow: 0 0.5vh 0 0 #71706e;
                }
            }

            .potato:nth-child(3) {
                .name {
                    background-color: #CD7F32;
                    box-shadow: 0 0.5vh 0 0 #804A00;
                }
            }

            .raise {
                animation: raise 0.5s ease-out;
            }

            .hide {
                animation: hide 0.5s ease-in forwards;
            }

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: auto;
                width: min(25vw, 25vh);
                top: min(6vw, 6vh);

                &.first {
                    width: min(35vw, 35vh);
                    top: 0;
                }

                .nameBox {
                    width: fit-content;
                    line-height: 1;
                    position: absolute;
                    margin: auto;
                    bottom: 0;
                    z-index: 1;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .place {
                    position: relative;
                    width: fit-content;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: -3px;

                    .name {
                        border-radius: 0 0 1vh 1vh;
                    }
                }

                .name {
                    font-family: "Lalezar";
                    z-index: 1;
                    font-size: 4vh;
                    color: black;
                    background-color: #FDC31F;
                    border-radius: 0.5vh;
                    box-shadow: 0 0.5vh 0 0 #D87715;
                    padding-left: 1vh;
                    padding-right: 1vh;
                    padding-top: 0.7vh;
                    padding-bottom: 0vh;
                    text-transform: uppercase;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            @keyframes raise {
                0% {
                    transform: translateY(100vh);
                }

                40% {
                    transform: translateY(0) scale(1.1);
                }

                60% {
                    transform: scale(1) rotate(5deg);
                }

                80% {
                    transform: scale(1.05) rotate(-5deg);
                }

                100% {
                    transform: scale(1) rotate(0);
                }
            }

            @keyframes hide {
                0% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(100vh);
                }
            }
        }
    }
}
