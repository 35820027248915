.defaultSection {
    --box-shadows: rgba(112,52,153,1);
}

.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    .playerSection {
        height: fit-content;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .potato {
            position: relative;
            height: min(80vw, 40vh);
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
            bottom: 0;
        }

        .text {
            font-size: min(9vw,7vh);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: black;
            font-family: "Lalezar";
            background-color: white;
            border-radius: min(2vw, 1vh);
            box-shadow: 0 min(2vw, 1vh) 0 0 var(--box-shadows);
            padding-left: min(3vw, 1.5vh);
            padding-right: min(3vw, 1.5vh);
            padding-top: min(2.4vw, 1.2vh);
            padding-bottom: 0;
            /*min-width: 100%;*/
            width: fit-content;
            line-height: 1;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        gap: 25px;
        margin-bottom: 15px;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: white;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                border: 0.25vh solid #561964;

                &:hover {
                    background-color: #EDB9E7 !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                font-size: 3vh;
                font-family: 'Lalezar';
                color: white;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
                text-shadow: 0.25vh 0.25vh 0.25vh #000000;
            }
        }
    }

    .button {
        font-size: 25px;
        text-align: center;
        color: black;
        height: fit-content;
        width: fit-content;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Lalezar';
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);
        background: linear-gradient(0deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
        box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #703499;
        border-radius: 10px;
        min-width: 150px;

        &.alt {
            background: linear-gradient(0deg, rgba(253,195,30,1) 0%, rgba(255,230,121,1) 100%);
        }
    }
}
