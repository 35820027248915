.player {
    --ready-box-text-color: white;
    --ready-box-bg-color: #1e78a0;
    --player-name-text-color: black;
    --player-name-bg-color: white;
    --player-name-shadow-color: #1e78a0;
    --points-text-color: white;
    --bonus-points-text-color: #fdc31f;
    --points-shadow-color: rgba(33,46,60,0.85);
    --answer-box-bg-color: white;
    --answer-box-shadow-color: #1e78a0;
    --validation-points-color: black;
}

.player {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 0.5vh;
    justify-content: start;
    align-items: center;
    transition: 1s height, 1s transform;
    height: 0%;
    transform: scale(0);
    z-index: 0;

    &.show {
        transform: scale(1);
        height: 22%;
    }


    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        width: auto;
    }

    .readyBox {
        position: absolute;
        font-family: "Lalezar";
        font-size: 2vh;
        color: var(--ready-box-text-color);
        background-color: var(--ready-box-bg-color);
        border-bottom-left-radius: 1vh;
        border-bottom-right-radius: 1vh;
        padding-left: 1vh;
        padding-right: 1vh;
        padding-top: 0.65vh;
        padding-bottom: 0.4vh;
        width: fit-content;
        line-height: 1;
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(0%);
        transition: transform 0.5s ease-in-out;
        z-index: 0;

        &.show {
            transform: translateY(100%);
        }
    }

    .nameAnchor {
        position: absolute;
        float: left;
        bottom: 0;
        z-index: 1;
        min-width: 100%;

        .name {
            font-family: "Lalezar";
            font-size: 2.75vh;
            color: var(--player-name-text-color);
            background-color: var(--player-name-bg-color);
            border-radius: 0.5vh;
            box-shadow: 0 0.5vh 0 0 var(--player-name-shadow-color);
            padding-left: 1vh;
            padding-right: 1vh;
            padding-top: 0.6vh;
            padding-bottom: 0vh;
            width: fit-content;
            text-align: center;
            min-width: 8vh;
            line-height: 1;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            white-space: nowrap;

            &.large {
                font-size: 3.5vh;
            }

            &.medium {
                font-size: 2.75vh;
            }

            &.small {
                font-size: 2vh;
            }
        }

        &.right {
            float: right;
            right: 0;
        }
    }

    .textSection {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 50%;

        .pointsRow {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 3vh;

            .star {
                width: auto;
                height: 6vh;
            }

            .points {
                color: var(--points-text-color);
                text-shadow: 3px 3px 9px var(--points-shadow-color);
                font-size: 5vh;
                font-family: "Lalezar";
                margin-left: 1vh;
                transition: color linear 0.25s;

                &.bonus {
                    color: var(--bonus-points-text-color);
                }
            }
        }
    }

    .answerBox {
        position: absolute;
        top: -1vh;
        left: -1.5vh;
        width: fit-content;
        transform: scale(0) rotate(90deg);
        transition: transform 0.5s ease-in;
        transform-origin: bottom left;
        display: flex;
        flex-direction: row;
        background: var(--answer-box-bg-color);
        border-radius: 0.5vh;
        box-shadow: 0 0.75vh 0 0 var(--answer-box-shadow-color);
        height: 4vh;
        min-width: 5vh;
        padding-left: 2vh;
        padding-right: 2vh;
        z-index: 1;

        &.show {
            transform: scale(1) rotate(0deg);
        }

        .answerText {
            position: relative;
            font-family: "Lalezar";
            font-size: 4vh;
            top: -0.6vh;
            color: black;
            width: fit-content;
            text-align: center;
            z-index: 1;
            text-transform: uppercase;
        }

        .answerValidation {
            position: absolute;
            margin: auto;
            left: 0;
            right: -100%;
            top: -10%;
            width: 5.5vh;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scale(0);
            opacity: 0;
            transition: 0.5s cubic-bezier(0.36, 0, 0.66, -0.56) transform;

            &.show {
                /*opacity: 1;*/
                transform: scale(1);
                opacity: 1;
                transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) transform;
            }

            img {
                position: absolute;
                width: 100%;
            }

            .count {
                font-family: "Lalezar";
                color: var(--validation-points-color);
                font-size: 2.75vh;
                text-align: center;
                z-index: 1;
            }
        }

        .arrow {
            position: absolute;
            width: 0;
            height: 0;
            border-top: 2.4vh solid white;
            border-right: 2.4vh solid transparent;
            bottom: -2vh;
            left: 0;
            border-bottom-left-radius: 0.5vh;
            border-bottom-right-radius: 0.5vh;

            &.bg {
                border-top: 2.75vh solid var(--answer-box-shadow-color);
                border-right: 2.75vh solid transparent;
                bottom: -2.75vh;
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        .textSection {


            .pointsRow {
                flex-direction: row-reverse;
            }

            .points {
                text-align: end;
                margin-left: 0vh;
                margin-right: 1vh;
            }

            .pointAdd {
                left: 25%;
                right: unset;
            }
        }

        .answerValidation {
            right: 0;
            left: -100%;
        }

        .answerBox {
            transform-origin: bottom right;
            transform: scale(0) rotate(-90deg);
            flex-direction: row-reverse;
            margin-left: auto;
            right: -1vh;
            left: unset;

            &.show {
                transform: scale(1) rotate(0deg);
            }

            .arrow {
                border-top: 2.4vh solid white;
                border-left: 2.4vh solid transparent;
                border-right: unset;
                right: 0;
                left: unset;

                &.bg {
                    border-top: 2.75vh solid #1e78a0;
                    border-left: 2.75vh solid transparent;
                    bottom: -2.75vh;
                }
            }
        }
    }


    .pointAdd {
        position: absolute;
        color: var(--points-text-color);
        text-shadow: 0.5vh 0.5vh 1vh rgba(33,46,60,0.85);
        font-size: 4.75vh;
        font-family: "Lalezar";
        margin: auto;
        text-align: center;
        top: 0;
        bottom: 0;
        right: 25%;
        left: unset;
        transform: translateY(5vh);
        opacity: 1;
        animation: pointsAnim 2s ease-in-out forwards;

        &.bonus {
            color: var(--bonus-points-text-color);
            text-shadow: 0.5vh 0.5vh 1vh rgba(100,89,60,0.85);
        }
    }

    @keyframes pointsAnim {
        0% {
            transform: translateY(5vh);
            opacity: 1;
        }

        100% {
            transform: translateY(-2vh);
            opacity: 0;
        }
    }
}
