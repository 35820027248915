.loadingBG {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.anagram {
        background: linear-gradient(30deg, rgba(24,221,254,1) 0%, rgba(65,184,254,1) 100%);

        .progressBar {
            .fill {
                background-color: rgba(30,120,160,1);
            }
        }
    }

    &.maths {
        background: linear-gradient(30deg, rgba(119,113,248,1) 0%, rgba(83,255,186,1) 100%);

        .progressBar {
            .fill {
                background-color: rgba(29,118,158,1);
            }
        }
    }

    &.word {
        background: linear-gradient(30deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);

        .progressBar {
            .fill {
                background-color: rgba(112,52,153,1);
            }
        }
    }

    &.noBg {
        background-color: unset;
    }

    .animContainer {
        height: 40%;
    }

    .text {
        font-size: 3.75vh;
        text-align: center;
        margin-top: 7vh;
        color: #002026;
        font-family: "Lalezar";
    }

    .progressBar {
        height: fit-content;
        min-height: 5vh;
        width: 50vh;
        max-width: 95%;
        background-color: #e0e0de;
        border-radius: 5vh;
        margin: 1vh;
        border: solid black 0.5vh;
        position: relative;


        .fill {
            height: 100%;
            background-color: #69EABD;
            border-radius: inherit;
            text-align: right;
            height: 100%;
        }

        .label {
            padding: 0.5vh;
            width: fit-content;
            color: white;
            font-weight: bold;
            font-size: 2vh;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}
